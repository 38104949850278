import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import languages from '../../data/verb/VerbData.json';
import { useMotherLanguage } from '../../context/mother_language_context';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: '#ffffff',
  backgroundColor: '#42a5f5',
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const AutoCompleteInput = ({ setSelectedVerb }) => {
  const { motherLanguage } = useMotherLanguage();

  const options = languages.map((option) => {
    const firstLetter = option.italian[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const handleInputChange = (event, value) => {
    setSelectedVerb(value);
  };

  return (
    <div id='italian-verb-conjugations'>
      <Autocomplete
        id='grouped-demo'
        options={options.sort(
          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
        )}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) =>
          `${option.italian} (${
            motherLanguage ? option[motherLanguage] : 'No translation'
          })`
        }
        style={{ width: '100%', padding: '0px' }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Select a Verb'
            InputLabelProps={{ style: { fontSize: '14px' } }}
            sx={{
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline legend':
                {
                  width: '75px', // Legend genişliğini otomatik yapar
                },
            }}
          />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default AutoCompleteInput;
