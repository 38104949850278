import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { useParams, Link } from 'react-router-dom';
import InstructorData from '../../data/instructor/InstructorData.json';
import TrackVisibility from 'react-on-screen';
import Skill from '../../components/progressbar/ProgressbarItem';
const TeacherDetail = () => {
  const { id } = useParams();

  const teacher = InstructorData.filter(
    (instructor) => instructor.id === Number(id)
  )[0];

  return (
    <>
      <SEO title='Fast Learn Italian' />

      <Layout>
        <BreadcrumbOne
          title='Teacher Profile'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Teacher Profile'
        />

        <div className='edu-instructor-profile-area edu-section-gap bg-color-white'>
          <div className='container'>
            <div className='row g-5'>
              <div className='col-lg-4 pr--55'>
                <div className='instructor-profile-left'>
                  <div className='inner'>
                    <div className='thumbnail'>
                      <img
                        src={teacher?.image}
                        alt={teacher?.name}
                      />
                    </div>
                    <div className='content'>
                      <h5 className='title'>{teacher.name}</h5>

                      {teacher.mail && (
                        <div className='contact-with-info'>
                          <p>
                            <span>Email:</span>{' '}
                            <a href={`mailto:${teacher.mail}`}>
                              {teacher.mail}
                            </a>
                          </p>
                        </div>
                      )}

                      <ul className='social-share bg-transparent justify-content-center medium-size'>
                        {teacher.facebookUrl && (
                          <li>
                            <a href={teacher.facebookUrl}>
                              <i className='icon-Fb'></i>
                            </a>
                          </li>
                        )}

                        {teacher.linkedInUrl && (
                          <li>
                            <a href={teacher.linkedInUrl}>
                              <i className='icon-linkedin'></i>
                            </a>
                          </li>
                        )}
                      </ul>
                      <div className='contact-btn'>
                        <a
                          href={teacher.italki}
                          className='edu-btn'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Contact Me
                          <i className='icon-arrow-right-line-right'></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-8'>
                <div className='instructor-profile-right'>
                  <div className='inner'>
                    <div className='section-title text-start'>
                      <span className='pre-title'>About Me</span>
                      <h3 className='title'>Hello, I’m {teacher.name}</h3>
                      {teacher.descriptions.map((description) => (
                        <p className='description mt--40'>{description}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default TeacherDetail;
