import React from 'react';
import { Link } from 'react-router-dom';
import ScrollTopButton from './ScrollTopButton';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <>
      <footer className='eduvibe-footer-one edu-footer footer-style-default'>
        <div className='footer-top'>
          <div className='container eduvibe-animated-shape'>
            <div className='row g-5'>
              <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                <h5 className='widget-title'>Social Media</h5>
                <div className='edu-footer-widget'>
                  <ul className='social-share'>
                    <li>
                      <a
                        href='https://www.instagram.com/fastlearnitalian/'
                        style={{ lineHeight: '50px' }}
                      >
                        <InstagramIcon style={{ fontSize: '28px' }} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                <div className='edu-footer-widget explore-widget'>
                  <h5 className='widget-title'>Fast Learn Italian</h5>
                  <div className='inner'>
                    <ul className='footer-link link-hover'>
                      <li>
                        <Link to='/privacy-policy'>
                          <i className='icon-Double-arrow'></i>Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to='/terms-of-use'>
                          <i className='icon-Double-arrow'></i>Terms of use
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-sm-6 col-12'>
                <div className='edu-footer-widget quick-link-widget'>
                  <h5 className='widget-title'>Useful Links</h5>
                  <div className='inner'>
                    <ul className='footer-link link-hover'>
                      <li>
                        <Link to='/italian-reading'>
                          <i className='icon-Double-arrow'></i>Reading
                        </Link>
                      </li>
                      <li>
                        <Link to='/italian-verb-conjugations'>
                          <i className='icon-Double-arrow'></i>Italian Verb
                          Conjugations
                        </Link>
                      </li>
                      <li>
                        <Link to='/italian-verb-list'>
                          <i className='icon-Double-arrow'></i>Italian Verb List
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className='shape-dot-wrapper shape-wrapper d-md-block d-none'>
              <div className='shape-image shape-image-1'>
                <img
                  src='/images/shapes/shape-21-01.png'
                  alt='Shape Thumb'
                />
              </div>
              <div className='shape-image shape-image-2'>
                <img
                  src='/images/shapes/shape-35.png'
                  alt='Shape Thumb'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='copyright-area copyright-default'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner text-center'>
                  <p>Copyright 2023 Fast Learn Italian. All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTopButton />
    </>
  );
};

export default Footer;
