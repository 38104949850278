import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import BreadcrumbOne from '../common/breadcrumb/BreadcrumbOne';

const TermsOfUse = () => {
  return (
    <>
      <SEO title='Terms of Use' />
      <Layout>
        <BreadcrumbOne
          title='Terms of Use'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Terms of Use'
        />
        <div className='edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white'>
          <div className='container'>
            <div className='row g-5'>
              <div className='content'>
                <h4 className='title'>Terms of Use</h4>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default TermsOfUse;
