import { useState } from 'react';
import { Link } from 'react-router-dom';
import Nav from './Nav';
import HeaderSticky from './HeaderSticky';
import ResponsiveMenu from './ResponsiveMenu';
import * as mdb from 'mdb-ui-kit';
import { useMotherLanguage } from '../../context/mother_language_context';

const languages = {
  english: 'flag-united-kingdom',
  turkish: 'flag-turkey',
  german: 'flag-germany',
  french: 'flag-france',
  spanish: 'flag-spain',
  portuguese: 'flag-portugal',
  swedish: 'flag-sweden',
  russian: 'flag-russia',
  chinese: 'flag-china',
  japanese: 'flag-japan',
  hindi: 'flag-india',
  arabic: 'flag-saudi-arabia',
};

const Header = ({ styles, disableSticky }) => {
  const { motherLanguage, setMotherLanguage } = useMotherLanguage();

  const [selectedLanguage, setSelectedLanguage] = useState(
    () => localStorage.getItem('motherLanguage') || 'english'
  );

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    localStorage.setItem('motherLanguage', language);
    setMotherLanguage(language);
  };

  const [offcanvasShow, setOffcanvasShow] = useState(false);
  const [searchPopup, setSearchPopup] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prevState) => !prevState);
  };

  const onSearchHandler = () => {
    setSearchPopup((prevState) => !prevState);
  };

  if (searchPopup) {
    document.body.classList.add('search-popup-active');
  } else {
    document.body.classList.remove('search-popup-active');
  }

  const sticky = HeaderSticky(118);
  const classes = sticky ? 'sticky' : '';
  const stickyStatus = disableSticky ? '' : ' header-sticky';

  return (
    <>
      <header
        className={`edu-header disable-transparent ${stickyStatus} ${
          styles || ''
        } ${classes || ''}`}
      >
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-xl-2 col-md-6 col-6'>
              <div className='logo'>
                <Link to={process.env.PUBLIC_URL + '/'}>
                  <img
                    className='logo-light'
                    src='/logo.png'
                    alt='Main Logo'
                  />
                </Link>
              </div>
            </div>

            <div className='col-lg-8 d-none d-xl-block'>
              <nav className='mainmenu-nav d-none d-lg-block'>
                <Nav />
              </nav>
            </div>

            <div className='col-lg-6 col-xl-2 col-md-6 col-6'>
              <div className='header-right d-flex justify-content-end'>
                <div className='header-quote'>
                  <div className='dropdown'>
                    <a
                      className='dropdown-toggle'
                      href='#'
                      id='Dropdown'
                      role='button'
                      data-mdb-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <i
                        className={`${languages[selectedLanguage]} flag m-0`}
                      ></i>
                    </a>

                    <ul
                      className='dropdown-menu'
                      aria-labelledby='Dropdown'
                    >
                      {Object.keys(languages).map((language) => (
                        <li key={language}>
                          <a
                            className='dropdown-item'
                            href='#'
                            onClick={() => handleLanguageChange(language)}
                          >
                            <i className={`${languages[language]} flag`}></i>
                            {language.charAt(0).toUpperCase() +
                              language.slice(1)}

                            {language === selectedLanguage && (
                              <i className='fa fa-check text-success ms-2'></i>
                            )}
                          </a>
                          <hr className='dropdown-divider' />
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className='hamberger quote-icon d-block d-xl-none'>
                    <button
                      className='hamberger-button'
                      onClick={onCanvasHandler}
                    >
                      <i className='ri-menu-line'></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <ResponsiveMenu
        show={offcanvasShow}
        onClose={onCanvasHandler}
        showSearch={searchPopup}
        onSearch={onSearchHandler}
      />
    </>
  );
};

export default Header;
