import React, { createContext, useState, useEffect, useContext } from 'react';

export const MotherLanguageContext = createContext({
  motherLanguage: null,
  setMotherLanguage: () => {},
});

export const MotherLanguageContextProvider = ({ children }) => {
  const [motherLanguage, setMotherLanguage] = useState(null);

  useEffect(() => {
    let savedLanguage = localStorage.getItem('motherLanguage');
    if (!savedLanguage) {
      savedLanguage = 'english';
      localStorage.setItem('motherLanguage', savedLanguage);
    }
    setMotherLanguage(savedLanguage);
  }, []);

  return (
    <MotherLanguageContext.Provider
      value={{ motherLanguage, setMotherLanguage }}
    >
      {children}
    </MotherLanguageContext.Provider>
  );
};

// A custom hook that simplifies the context usage
export const useMotherLanguage = () => useContext(MotherLanguageContext);
