// React
import { useContext } from 'react';
// Material UI
import { Grid } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
// Context
import { ShowHideTensesContext } from '../../context/show_hide_tenses_context';

const ShowHideTenses = () => {
  const showHideTensesContext = useContext(ShowHideTensesContext);

  if (!showHideTensesContext) {
    throw new Error(
      'Show/HideTenses must be used within a ShowHideTensesProvider'
    );
  }

  const {
    tenses,
    // setTenses,
    handleDisplayTense,
    handleClickShowHideAllTenses,
  } = showHideTensesContext;

  return (
    <>
      <br />
      <button
        class='edu-btn btn-small'
        onClick={handleClickShowHideAllTenses}
      >
        <span> {tenses.allTenses ? 'Hide All Tenses' : 'Show All Tenses'}</span>
      </button>

      <br />
      <br />
      <Grid container>
        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='presente'
                checked={tenses.presente}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>Presente</Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='passatoProssimo'
                checked={tenses.passatoProssimo}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Passato Prossimo
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='imperfetto'
                checked={tenses.imperfetto}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>Imperfetto</Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='trapassatoProssimo'
                checked={tenses.trapassatoProssimo}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Trapassato Prossimo
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='passatoRemoto'
                checked={tenses.passatoRemoto}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Passato Remoto
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='trapassatoRemoto'
                checked={tenses.trapassatoRemoto}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Trapassato Remoto
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='futuroSemplice'
                checked={tenses.futuroSemplice}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Futuro Semplice
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='futuroAnteriore'
                checked={tenses.futuroAnteriore}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Futuro Anteriore
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='condizionalePresente'
                checked={tenses.condizionalePresente}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Condizionale Presente
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='condizionalePassato'
                checked={tenses.condizionalePassato}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Condizionale Passato
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='congiuntivoPresente'
                checked={tenses.congiuntivoPresente}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Congiuntivo Presente
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='congiuntivoPassato'
                checked={tenses.congiuntivoPassato}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Congiuntivo Passato
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='congiuntivoImperfetto'
                checked={tenses.congiuntivoImperfetto}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Congiuntivo Imperfetto
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='congiuntivoTrapassato'
                checked={tenses.congiuntivoTrapassato}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>
                Congiuntivo Trapassato
              </Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='imperativo'
                checked={tenses.imperativo}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>Imperativo</Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='gerundio'
                checked={tenses.gerundio}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>Gerundio</Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='infinito'
                checked={tenses.infinito}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>Infinito</Typography>
            }
          />
        </Grid>

        <Grid
          item
          lg={4}
          xs={12}
        >
          <FormControlLabel
            control={
              <Switch
                name='participio'
                checked={tenses.participio}
                onChange={handleDisplayTense}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography className='switch-font-size'>Participio</Typography>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ShowHideTenses;
