import React, { useContext, useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import { ShowHideTensesContext } from '../../context/show_hide_tenses_context';
// Material UI
import { Grid } from '@mui/material';
import AutoCompleteInput from './AutoCompleteInput';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
// import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ShowHideTenses from './ShowHideTenses';

import Accordion from 'react-bootstrap/Accordion';

import { handleChangePronoun, handleChangeTense } from '../../helper/Helper';

import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';

const VerbConjugations = () => {
  const { tenses } = useContext(ShowHideTensesContext);

  const [selectedVerb, setSelectedVerb] = useState(null);
  return (
    <>
      <SEO title='Italian Verb Conjugations' />

      <Layout>
        <BreadcrumbOne
          title='Italian Verb Conjugations'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Italian Verb Conjugations'
        />

        <br />

        <div className='container'>
          <div className='row mt-5'>
            <div className='col-lg-12'>
              <AutoCompleteInput setSelectedVerb={setSelectedVerb} />
            </div>
          </div>

          <div className='row mt-5'>
            <div className='col-lg-12'>
              <div className='accordion-style-1'>
                <Accordion bsPrefix='edu-accordion'>
                  <Accordion.Item
                    bsPrefix='edu-accordion-item'
                    eventKey='0'
                  >
                    <Accordion.Header className='verb-conjugations-accordion'>
                      Show/Hide Tenses
                    </Accordion.Header>
                    <Accordion.Body bsPrefix='edu-accordion-body'>
                      <ShowHideTenses />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>

          <div className='row mt-5 mb-5'>
            {selectedVerb ? (
              Object.entries(selectedVerb.italianTenses).map(
                ([tense, conjugations]) => {
                  if (!tenses[tense]) {
                    return null;
                  }

                  return (
                    <div className='col-lg-4'>
                      <div class='tag-list'>
                        <a
                          href='/'
                          style={{ backgroundColor: '#525FE1', color: '#fff' }}
                          className='w-100 text-center'
                          onClick={(e) => e.preventDefault()}
                        >
                          {handleChangeTense(tense)}
                        </a>
                      </div>

                      {Object.entries(conjugations).map(
                        ([pronoun, conjugation]) => (
                          <p class='description text-center mt-5'>
                            {handleChangePronoun(pronoun)} {conjugation}
                          </p>
                        )
                      )}
                    </div>
                  );
                }
              )
            ) : (
              <div className='col-lg-12'>
                <div
                  class='alert alert-info'
                  role='alert'
                >
                  Please select a verb!
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default VerbConjugations;
