import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/scrolltotop/ScrollToTop';
import CourseFilterSidebar from './pages/course/CourseFilterSidebar';
import AboutUsOne from './pages/innerpages/AboutUsOne';
import AboutUsTwo from './pages/innerpages/AboutUsTwo';
import AboutUsThree from './pages/innerpages/AboutUsThree';
import BlogGridOne from './pages/blog/BlogGridOne';
import BlogGridTwo from './pages/blog/BlogGridTwo';
import BlogGridThree from './pages/blog/BlogGridThree';
import BlogCarousel from './pages/blog/BlogCarousel';
import BlogLoadMore from './pages/blog/BlogLoadMore';
import BlogStandard from './pages/blog/BlogStandard';
import BlogGridRightSidebar from './pages/blog/BlogGridRightSidebar';
import BlogGridLeftSidebar from './pages/blog/BlogGridLeftSidebar';
import BlogDetailsOne from './pages/detailspages/BlogDetailsOne';
import BlogDetailsTwo from './pages/detailspages/BlogDetailsTwo';
import BlogDetailsThree from './pages/detailspages/BlogDetailsThree';
import CategoryArchive from './pages/archive/CategoryArchive';
import TagArchive from './pages/archive/TagArchive';
import AuthorArchive from './pages/archive/AuthorArchive';
import ComingSoon from './pages/innerpages/ComingSoon';
import ContactMe from './pages/innerpages/ContactMe';
import ContactUs from './pages/innerpages/ContactUs';
import CourseOne from './pages/course/CourseOne';
import CourseTwo from './pages/course/CourseTwo';
import CourseThree from './pages/course/CourseThree';
import CourseFour from './pages/course/CourseFour';
import CourseFive from './pages/course/CourseFive';
import CourseFilterOne from './pages/course/CourseFilterOne';
import CourseFilterTwo from './pages/course/CourseFilterTwo';
import CourseCarousel from './pages/course/CourseCarousel';
import CourseLoadMore from './pages/course/CourseLoadMore';
import CourseCategoryArchive from './pages/archive/CourseCategoryArchive';
import CourseDetails from './pages/detailspages/CourseDetails';
import CourseDetailsTwo from './pages/detailspages/CourseDetailsTwo';
import EventGrid from './pages/innerpages/EventGrid';
import EventList from './pages/innerpages/EventList';
import EventLoadMore from './pages/innerpages/EventLoadMore';
import EventCarousel from './pages/innerpages/EventCarousel';
import EventDetails from './pages/detailspages/EventDetails';
import Faq from './pages/innerpages/Faq';
import GalleryGrid from './pages/innerpages/GalleryGrid';
import GalleryMasonry from './pages/innerpages/GalleryMasonry';
import GalleryLoadMore from './pages/innerpages/GalleryLoadMore';
import HomeOne from './pages/homepages/HomeOne';
import HomeTwo from './pages/homepages/HomeTwo';
import HomeThree from './pages/homepages/HomeThree';
import HomeFour from './pages/homepages/HomeFour';
import HomeFive from './pages/homepages/HomeFive';
import InstructorPageOne from './pages/innerpages/InstructorPageOne';
import InstructorPageTwo from './pages/innerpages/InstructorPageTwo';
import InstructorPageThree from './pages/innerpages/InstructorPageThree';
import LandingDemo from './pages/LandingDemo';
import LoginRegister from './pages/innerpages/LoginRegister';
import Pricing from './pages/innerpages/Pricing';
import PurchaseGuide from './pages/innerpages/PurchaseGuide';
import Testimonial from './pages/innerpages/Testimonial';
import InstructorDetails from './pages/detailspages/InstructorDetails';
import Error from './pages/innerpages/Error';
import VerbConjugations from './pages/verbs/VerbConjugations';
import VerbConjugationsExercises from './pages/verbs/VerbConjugationsExercises';
import VerbList from './pages/verbs/VerbList';
import Contact from './pages/contact/Contact';

// Import Css Here
import './assets/scss/style.scss';
import Home from './pages/home/Home';
import TeacherDetail from './pages/teacher/TeacherDetail';
import Reading from './pages/reading/Reading';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          {/* <Route exact path='/' element={<LandingDemo/>}/> */}

          <Route
            exact
            path='/'
            element={<Home />}
          />
          <Route
            exact
            path='/italian-reading'
            element={<Reading />}
          />
          <Route
            exact
            path='/italian-verb-conjugations'
            element={<VerbConjugations />}
          />
          <Route
            exact
            path='/italian-verb-conjugations-exercises'
            element={<VerbConjugationsExercises />}
          />
          <Route
            exact
            path='/italian-verb-list'
            element={<VerbList />}
          />
          <Route
            exact
            path='/teacher-detail/:id'
            element={<TeacherDetail />}
          />
          <Route
            exact
            path='/contact'
            element={<Contact />}
          />
          <Route
            exact
            path='/privacy-policy'
            element={<PrivacyPolicy />}
          />
          <Route
            exact
            path='/terms-of-use'
            element={<TermsOfUse />}
          />
          {/* <Route
            exact
            path={`${process.env.PUBLIC_URL + '/home-2'}`}
            element={<HomeTwo />}
          /> */}
          <Route
            path='*'
            element={<Error />}
          />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
