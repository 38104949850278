export const handleChangePronoun = (pPronoun) => {
  switch (pPronoun) {
    case 'io':
      return 'Io';
    case 'tu':
      return 'Tu';
    case 'luiLei':
      return 'Lui/Lei';
    case 'noi':
      return 'Noi';
    case 'voi':
      return 'Voi';
    case 'loro':
      return 'Loro';
    case 'presente':
      return '';
    case 'passato':
      return '';
  }
};

export const handleChangeTense = (pTense) => {
  switch (pTense) {
    case 'presente':
      return 'Presente';
    case 'passatoProssimo':
      return 'Passato Prossimo';
    case 'imperfetto':
      return 'Imperfetto';
    case 'trapassatoProssimo':
      return 'Trapassato Prossimo';
    case 'passatoRemoto':
      return 'Passato Remoto';
    case 'trapassatoRemoto':
      return 'Trapassato Remoto';
    case 'futuroSemplice':
      return 'Futuro Semplice';
    case 'futuroAnteriore':
      return 'Futuro Anteriore';
    case 'condizionalePresente':
      return 'Condizionale Presente';
    case 'condizionalePassato':
      return 'Condizionale Passato';
    case 'congiuntivoPresente':
      return 'Congiuntivo Presente';
    case 'congiuntivoPassato':
      return 'Congiuntivo Passato';
    case 'congiuntivoImperfetto':
      return 'Congiuntivo Imperfetto';
    case 'congiuntivoTrapassato':
      return 'Congiuntivo Trapassato';
    case 'imperativo':
      return 'Imperativo';
    case 'gerundio':
      return 'Gerundio';
    case 'infinito':
      return 'Infinito';
    case 'participio':
      return 'Participio';
  }
};
