import React, { useEffect, useState } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import languages from '../../data/verb/VerbData.json';
import { handleChangeTense } from '../../helper/Helper';
import { useMotherLanguage } from '../../context/mother_language_context';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

const initialFormState = {
  presente: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  passatoProssimo: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  imperfetto: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  trapassatoProssimo: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  passatoRemoto: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  trapassatoRemoto: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  futuroSemplice: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  futuroAnteriore: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  condizionalePresente: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  condizionalePassato: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  congiuntivoPresente: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  congiuntivoPassato: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  congiuntivoImperfetto: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  congiuntivoTrapassato: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  imperativo: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  gerundio: {
    presente: '',
    passato: '',
  },
  infinito: {
    presente: '',
    passato: '',
  },
  participio: {
    presente: '',
    passato: '',
  },
};

const initialCheckState = {
  presente: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  passatoProssimo: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  imperfetto: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  trapassatoProssimo: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  passatoRemoto: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  trapassatoRemoto: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  futuroSemplice: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  futuroAnteriore: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  condizionalePresente: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  condizionalePassato: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  congiuntivoPresente: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  congiuntivoPassato: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  congiuntivoImperfetto: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  congiuntivoTrapassato: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  imperativo: {
    io: '',
    tu: '',
    luiLei: '',
    noi: '',
    voi: '',
    loro: '',
  },
  gerundio: {
    presente: '',
    passato: '',
  },
  infinito: {
    presente: '',
    passato: '',
  },
  participio: {
    presente: '',
    passato: '',
  },
};

const VerbConjugationsExercises = () => {
  const { motherLanguage } = useMotherLanguage();
  const [selectedVerb, setSelectedVerb] = useState(null);
  const [formState, setFormState] = useState(initialFormState);
  const [checkState, setCheckState] = useState(initialCheckState);

  useEffect(() => {
    fetchNewVerb();
  }, []);

  const convertToVerb = (item) => {
    return {
      ...item,
      firstLetter: item.italian[0],
    };
  };

  const fetchNewVerb = () => {
    const randomIndex = Math.floor(Math.random() * languages.length);
    setSelectedVerb(convertToVerb(languages[randomIndex]));
    setFormState(initialFormState);
    // setCheckState(initialCheckState);
    const newCheckState = JSON.parse(JSON.stringify(initialCheckState));
    for (let tense in newCheckState) {
      for (let subject in newCheckState[tense]) {
        newCheckState[tense][subject] = 'incorrect';
      }
    }
    setCheckState(newCheckState);
  };

  const handleInputChange = (event, tense, subject) => {
    const value = event.target.value.trim().toLowerCase();
    setFormState((prevState) => ({
      ...prevState,
      [tense]: { ...prevState[tense], [subject]: value },
    }));
    if (!selectedVerb) {
      return;
    }
    if (value === selectedVerb.italianTenses[tense][subject]) {
      setCheckState((prevState) => ({
        ...prevState,
        [tense]: { ...prevState[tense], [subject]: 'correct' },
      }));
    } else {
      setCheckState((prevState) => ({
        ...prevState,
        [tense]: { ...prevState[tense], [subject]: 'incorrect' },
      }));
    }
  };

  const handleShowAnswers = (pTense) => {
    if (!selectedVerb) {
      return;
    }
    setFormState((prevState) => ({
      ...prevState,
      [pTense]: selectedVerb.italianTenses[pTense],
    }));
    setCheckState((prevState) => {
      const updatedState = { ...prevState };
      for (const key in updatedState[pTense]) {
        updatedState[pTense][key] = 'correct';
      }
      return updatedState;
    });
  };

  const handleClearAnswers = (pTense) => {
    setFormState((prevState) => ({
      ...prevState,
      [pTense]: initialFormState[pTense],
    }));

    setCheckState((prevState) => {
      const updatedState = { ...prevState };
      for (const key in updatedState[pTense]) {
        updatedState[pTense][key] = 'incorrect';
      }
      return updatedState;
    });
  };

  return (
    <>
      <SEO title='Italian Verb Conjugations Exercises' />

      <Layout>
        <BreadcrumbOne
          title='Italian Verb Conjugations Exercises'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Italian Verb Conjugations Exercises'
        />

        <>
          <div class='container mt-5'>
            <div class='row align-items-center mb-5'>
              <div class='col'>
                {selectedVerb && (
                  <div className='section-title '>
                    <span class='pre-title'>{`${selectedVerb.italian} (${selectedVerb[motherLanguage]} )`}</span>
                  </div>
                )}
              </div>
              <div class='col-auto'>
                <button
                  class='edu-btn btn-small'
                  onClick={fetchNewVerb}
                >
                  <span> New Verb</span>
                </button>
              </div>
            </div>

            <div className='row'>
              {selectedVerb !== null &&
                Object.entries(selectedVerb.italianTenses).map(
                  ([tense, conjugations]) => (
                    <div className='col-lg-6 mb-5'>
                      <p>
                        <strong>{handleChangeTense(tense)}</strong>
                      </p>

                      {Object.entries(conjugations).map(
                        ([subject, correctConjugation]) => {
                          if (
                            typeof formState[tense] === 'object' &&
                            typeof formState[tense][subject] !== 'undefined'
                          ) {
                            return (
                              <div
                                className='input-group mb-3 verb-conjugations-exercises'
                                key={subject}
                              >
                                <span className='input-group-text'>
                                  {subject}
                                </span>
                                <input
                                  type='text'
                                  className='form-control'
                                  aria-label='italian verb conjugations'
                                  value={formState[tense][subject]}
                                  onChange={(e) =>
                                    handleInputChange(e, tense, subject)
                                  }
                                />
                                <span className='input-group-text'>
                                  {checkState[tense][subject] === 'correct' ? (
                                    <CheckCircleIcon color='success' />
                                  ) : checkState[tense][subject] ===
                                    'incorrect' ? (
                                    <CancelIcon color='error' />
                                  ) : (
                                    <CancelIcon color='error' />
                                  )}
                                </span>
                              </div>
                            );
                          }
                          return null;
                        }
                      )}
                      <div className='row justify-content-between mt-4'>
                        <div className='col-auto'>
                          <button
                            class='rn-btn edu-btn btn-small'
                            onClick={() => handleShowAnswers(tense)}
                          >
                            <span>Show</span>
                            <VisibilityIcon className='ms-2' />
                          </button>
                        </div>
                        <div className='col-auto'>
                          <button
                            class='edu-btn btn-small'
                            onClick={() => handleClearAnswers(tense)}
                          >
                            <span>Clear</span>
                            <DeleteIcon className='ms-2' />
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </>
      </Layout>
    </>
  );
};

export default VerbConjugationsExercises;
