import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ParallaxProvider } from 'react-scroll-parallax';
import { MotherLanguageContextProvider } from './context/mother_language_context';
import { ShowHideTensesContextProvider } from './context/show_hide_tenses_context';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <ParallaxProvider>
      <MotherLanguageContextProvider>
        <ShowHideTensesContextProvider>
          <App />
        </ShowHideTensesContextProvider>
      </MotherLanguageContextProvider>
    </ParallaxProvider>
  </StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
