import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
  return (
    <ul className='mainmenu'>
      <li>
        <Link to='/'>Home</Link>
      </li>

      <li>
        <Link to='/italian-reading'>Reading</Link>
      </li>
      <li className='has-droupdown'>
        <Link to='#'>Verbs</Link>
        <ul className='submenu'>
          <li>
            <Link to='/italian-verb-conjugations'>Verb Conjugations</Link>
          </li>
          <li>
            <Link to='/italian-verb-conjugations-exercises'>
              Verb Conjugations Exercises
            </Link>
          </li>
          <li>
            <Link to='/italian-verb-list'>Verb List</Link>
          </li>
        </ul>
      </li>

      <li>
        <Link to='/contact'>Contact</Link>
      </li>
    </ul>
  );
};
export default Nav;
