import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BannerFour from '../../components/banner/BannerFour';
import HomeFourServices from '../../components/home-four/HomeFourServices';
import HomeFourAbout from '../../components/home-four/HomeFourAbout';
import HomeFourCourses from '../../components/home-four/HomeFourCourses';
import VideoSectionOne from '../../components/video-section/VideoSectionOne';
import HomeFourTeam from '../../components/home-four/HomeFourTeam';
import CallToActionTwo from '../../components/cta/CallToActionTwo';
import TestimonialSectionOne from '../../components/testimonial-section/TestimonialSectionOne';
import HomeFourBlog from '../../components/home-four/HomeFourBlog';
import NewsLettterOne from '../../components/newsletter/NewsLettterOne';
import HomeThreeService from '../../components/home-three/HomeThreeService';
import HomeFiveCategories from '../../components/home-five/HomeFiveCategories';
import HomeFiveProgress from '../../components/home-five/HomeFiveProgress';
import FooterOne from '../../common/footer/FooterOne';

const Home = () => {
  return (
    <>
      <SEO title='Fast Learn Italian' />

      <Layout>
        <BannerFour />
        <HomeFiveCategories />
        {/* <HomeThreeService /> */}
        <HomeFourTeam />
        {/* <CallToActionTwo /> */}
        {/* <HomeFiveProgress /> */}
        <NewsLettterOne />
      </Layout>
    </>
  );
};

export default Home;
