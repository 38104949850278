// React
import { useState } from 'react';
// Theme
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
// Data
import verbs from '../../data/verb/VerbData.json';
// Context
import { useMotherLanguage } from '../../context/mother_language_context';

const VerbList = () => {
  const { motherLanguage } = useMotherLanguage();
  const [searchText, setSearchText] = useState('');
  const [sortBy, setSortBy] = useState('italian');
  const [sortOrder, setSortOrder] = useState('asc');
  const rows = verbs;

  const handleSort = (column) => {
    setSortBy(column);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortedRows = [...rows].sort((a, b) => {
    const aColumn = a[sortBy] || '';
    const bColumn = b[sortBy] || '';

    if (aColumn && bColumn) {
      return sortOrder === 'asc'
        ? aColumn.localeCompare(bColumn)
        : bColumn.localeCompare(aColumn);
    } else {
      return 0;
    }
  });

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filteredRows = searchText
    ? sortedRows.filter(
        (row) =>
          row.italian.toLowerCase().includes(searchText.toLowerCase()) ||
          row.italianSentence
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          (row[motherLanguage] &&
            row[motherLanguage]
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (row[motherLanguage + 'Sentence'] &&
            row[motherLanguage + 'Sentence']
              .toLowerCase()
              .includes(searchText.toLowerCase()))
      )
    : sortedRows;

  return (
    <>
      <SEO title='Italian Verb List' />

      <Layout>
        <BreadcrumbOne
          title='Italian Verb List'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Italian Verb List'
        />
        <div className='container pt-5'>
          <div className='row mt-5'>
            <div className='col-lg-12'>
              <div className='form-group'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search'
                  value={searchText}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
          <div className='row mt-5 mb-5'>
            <div className='col-lg-12'>
              {filteredRows.length === 0 ? (
                <div
                  class='alert alert-info'
                  role='alert'
                >
                  No verb found matching your search criteria!
                </div>
              ) : (
                <table
                  class='table'
                  style={{ fontSize: '16px' }}
                >
                  <thead>
                    <tr>
                      <th onClick={() => handleSort('italian')}>ITALIAN</th>
                      <th
                        className='d-none d-lg-table-cell'
                        onClick={() => handleSort('italianSentence')}
                      >
                        SENTENCE
                      </th>
                      <th
                        onClick={() => handleSort(motherLanguage || 'english')}
                      >
                        {motherLanguage?.toUpperCase() || 'ENGLISH'}
                      </th>
                      <th
                        className='d-none d-lg-table-cell'
                        onClick={() =>
                          handleSort(
                            `${motherLanguage}Sentence` || 'englishSentence'
                          )
                        }
                      >
                        SENTENCE
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredRows.map((row, index) => (
                      <tr key={index}>
                        <td>{row.italian}</td>
                        <td className='d-none d-lg-table-cell'>
                          {row.italianSentence}
                        </td>
                        <td>{row[motherLanguage] || row.english}</td>
                        <td className='d-none d-lg-table-cell'>
                          {row[`${motherLanguage}Sentence`] ||
                            row.englishSentence}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default VerbList;
