import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';
import BreadcrumbOne from '../common/breadcrumb/BreadcrumbOne';

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title='Privacy Policy' />
      <Layout>
        <BreadcrumbOne
          title='Privacy Policy'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Privacy Policy'
        />
        <div className='edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white'>
          <div className='container'>
            <div className='row g-5'>
              <div className='content'>
                <h4 className='title'>Information We Collect</h4>
                <p></p>

                <h4 className='title'>Log Data</h4>
                <p></p>

                <h4 className='title'>Device Data</h4>
                <p></p>

                <h4 className='title'>How to Purchase a Course? </h4>
                <p></p>

                <h4 className='title'>Accepted Credit Cards</h4>
                <p></p>

                <h4 className='title'>Cookies</h4>
                <p></p>

                <h4 className='title'>Changes to This Policy</h4>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default PrivacyPolicy;
