// React
import { createContext, useState } from 'react';

export const ShowHideTensesContext = createContext(undefined);

export const ShowHideTensesContextProvider = ({ children }) => {
  const [tenses, setTenses] = useState({
    allTenses: true,
    presente: true,
    passatoProssimo: true,
    imperfetto: true,
    trapassatoProssimo: true,
    passatoRemoto: true,
    trapassatoRemoto: true,
    futuroSemplice: true,
    futuroAnteriore: true,
    condizionalePresente: true,
    condizionalePassato: true,
    congiuntivoPresente: true,
    congiuntivoPassato: true,
    congiuntivoImperfetto: true,
    congiuntivoTrapassato: true,
    imperativo: true,
    gerundio: true,
    infinito: true,
    participio: true,
  });

  const handleDisplayTense = (event) => {
    setTenses({ ...tenses, [event.target.name]: event.target.checked });
  };

  const handleClickShowHideAllTenses = () => {
    const newAllTensesValue = !tenses.allTenses;
    const newTensesState = {};

    for (let key in tenses) {
      newTensesState[key] = newAllTensesValue;
    }

    setTenses(newTensesState);
  };

  return (
    <ShowHideTensesContext.Provider
      value={{
        tenses,
        setTenses,
        handleDisplayTense,
        handleClickShowHideAllTenses,
      }}
    >
      {children}
    </ShowHideTensesContext.Provider>
  );
};
