import React, { useState, useContext } from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { readingData } from '../../data/reading/ReadingData';

const levels = [
  {
    id: 1,
    slug: 'elementary',
    label: 'Elementary',
  },
  {
    id: 2,
    slug: 'intermediate',
    label: 'Intermediate',
  },
  {
    id: 3,
    slug: 'advanced',
    label: 'Advanced',
  },
];

const elementaryReading = readingData.filter(
  (data) => data.level === 'Elementary'
);

const intermediateReading = readingData.filter(
  (data) => data.level === 'Intermediate'
);

const advancedReading = readingData.filter((data) => data.level === 'Advanced');

const CustomToggle = ({ children, eventKey }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <button
      type='button'
      onClick={decoratedOnClick}
      aria-expanded={isCurrentEventKey ? true : false}
      className='edu-accordion-button'
    >
      {children}
    </button>
  );
};

const Reading = ({ wrapperClass }) => {
  const [selectedItem, setSelectedItem] = useState('elementary');
  const [activeId1, setActiveId1] = useState('0');
  const [activeId2, setActiveId2] = useState('0');
  const [activeId3, setActiveId3] = useState('0');
  const [activeId4, setActiveId4] = useState('0');
  const [activeId5, setActiveId5] = useState('0');
  const [activeId6, setActiveId6] = useState('0');

  function toggleActive1(id) {
    if (activeId1 === id) {
      setActiveId1(null);
    } else {
      setActiveId1(id);
    }
  }
  function toggleActive2(id) {
    if (activeId2 === id) {
      setActiveId2(null);
    } else {
      setActiveId2(id);
    }
  }
  function toggleActive3(id) {
    if (activeId3 === id) {
      setActiveId3(null);
    } else {
      setActiveId3(id);
    }
  }
  function toggleActive4(id) {
    if (activeId4 === id) {
      setActiveId4(null);
    } else {
      setActiveId4(id);
    }
  }
  function toggleActive5(id) {
    if (activeId5 === id) {
      setActiveId5(null);
    } else {
      setActiveId5(id);
    }
  }
  function toggleActive6(id) {
    if (activeId6 === id) {
      setActiveId6(null);
    } else {
      setActiveId6(id);
    }
  }
  return (
    <>
      <SEO title='Fast Learn Italian' />

      <Layout>
        <BreadcrumbOne
          title='Italian Reading'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Italian Reading'
        />
        <div
          className={`edu-accordion-area accordion-shape-1 edu-section-gap bg-color-white ${
            wrapperClass ? wrapperClass : ''
          } `}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title text-center'>
                  <h3 className='title'>Italian Reading</h3>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-12'>
                <ul className='edu-tab-nav nav nav-tabs mt--40'>
                  {levels.map((button) => (
                    <li
                      className='nav-item'
                      key={button.id}
                    >
                      <button
                        className={
                          button.slug === selectedItem
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        type='button'
                        aria-label={button.label}
                        onClick={() => setSelectedItem(button.slug)}
                      >
                        {button.label}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {selectedItem === 'elementary' && (
              <div className='row g-5 align-items-center mt--20'>
                <div className='col-lg-12'>
                  <div className='accordion-style-1'>
                    <Accordion
                      bsPrefix='edu-accordion'
                      defaultActiveKey='0'
                      flush
                    >
                      {elementaryReading.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive1(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId1 === i.toString() ? 'active-item' : ''
                          }`}
                        >
                          <div className='edu-accordion-header'>
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body
                            bsPrefix='edu-accordion-body'
                            dangerouslySetInnerHTML={{ __html: accordion.body }}
                          ></Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            )}

            {selectedItem === 'intermediate' && (
              <div className='row g-5 align-items-center mt--20'>
                <div className='col-lg-12'>
                  <div className='accordion-style-1'>
                    <Accordion
                      bsPrefix='edu-accordion'
                      defaultActiveKey='0'
                      flush
                    >
                      {intermediateReading.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive3(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId3 === i.toString() ? 'active-item' : ''
                          }`}
                        >
                          <div className='edu-accordion-header'>
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body
                            bsPrefix='edu-accordion-body'
                            dangerouslySetInnerHTML={{ __html: accordion.body }}
                          ></Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            )}

            {selectedItem === 'advanced' && (
              <div className='row g-5 align-items-center mt--20'>
                <div className='col-lg-12'>
                  <div className='accordion-style-1'>
                    <Accordion
                      bsPrefix='edu-accordion'
                      defaultActiveKey='0'
                      flush
                    >
                      {advancedReading.map((accordion, i) => (
                        <Accordion.Item
                          eventKey={i.toString()}
                          key={i}
                          onClick={() => toggleActive5(i.toString())}
                          bsPrefix={`edu-accordion-item ${
                            activeId5 === i.toString() ? 'active-item' : ''
                          }`}
                        >
                          <div className='edu-accordion-header'>
                            <CustomToggle eventKey={i.toString()}>
                              {accordion.title}
                            </CustomToggle>
                          </div>
                          <Accordion.Body
                            bsPrefix='edu-accordion-body'
                            dangerouslySetInnerHTML={{ __html: accordion.body }}
                          ></Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Reading;
